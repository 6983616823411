










































































































































































































































































































































































































import _ from "lodash";
import { Coach, CoachingGroup, Company } from "@/models/models";
import { batchCollection, groupCollection } from "@/utils/db";
import { mapGetters } from "vuex";
import Vue from "vue";
import { UniqueIdGenerator } from "@/utils/uid";
import { enableUntilChapters } from "@/utils/presets";
import moment from "moment";
import { getGroupName } from "@/utils/utils";
import { Course } from "@/models/Course";

export default Vue.extend({
  name: "AddBatchWorkflow",
  data: () => ({
    e1: 1,
    formValid: {
      step3Valid: false,
      // step4Valid: -> Computed
      step5Valid: false,
    },
    rules: {
      nameRules: [(v) => !!v || "Name is required"],
      courseRules: [(v) => !!v || "Course is required"],
    },
    workflowData: {
      course: null as string | null,
      batch: {
        name: null as string | null,
        id: null as string | null,
      },
      group: {
        newGroupIds: [] as CoachingGroup[],
      },
      user: {
        selectedGroupIndex: 0,
        addUserDialog: false,
        addUserCompany: {} as any,
        addUserEmail: null,
      },
    },
    addGroupData: {
      addGroupName: "",
      addGroupCourse: "",
      addGroupCoachImage: "",
      addGroupCoachName: "",
      numberCoachings: 5,
      weeklyFrequency: 2,
      firstCoachingStart: "",
      firstCoachingDuration: 1.5,
      coachObject: null as any,
    },
    enableUntilChapters: enableUntilChapters,
    defaultCoaching: {
      coach: "",
      coachImage: "",
      courseId: "" as any,
      enableUntilChapter: "",
      end: "",
      start: "",
    },
    addGroupDialog: {
      active: false,
      usePreset: true,
      useCoachCollection: true,
      coachingFormValid: false,
    },
  }),
  computed: {
    ...mapGetters([
      "allCourses",
      "coaches",
      "groups",
      "companies",
      "sortedCompanies",
    ]),
    /** Return all course ids like (c1,c2) without locale postfix (_de) */
    allCourseIds(): string[] {
      let ids = new Set<string>(
        this.allCourses.map((i: { id: string }) => i.id.split("_")[0])
      );
      return [...ids];
    },
    allCourseObjects(): any[] {
      let res: any[] = [];
      if (this.allCourseIds) {
        res = this.allCourseIds.map((courseId) => {
          let course = this.allCourses.find(
            (c) => c.id.split("_")[0] === courseId
          );
          return { title: course.title, id: courseId };
        });
      }
      return res;
    },
    step4Valid(): boolean {
      if (!this.workflowData.batch.id) {
        return false;
      }
      return this.workflowData.group.newGroupIds.length > 0;
    },
    step5UserFormValid(): boolean {
      if (
        this.selectedGroup &&
        this.workflowData.user.addUserEmail &&
        this.workflowData.user.addUserCompany
      ) {
        const courseValid =
          this.workflowData.user.addUserCompany.courses.filter((course) => {
            const email = this.workflowData.user.addUserEmail || "";
            if (
              course.courseId === this.selectedGroup.course &&
              course.users.includes(email)
            ) {
              return true;
            } else {
              return false;
            }
          }).length > 0;

        let emailNotInGroup = false;
        if (this.selectedGroup.users.length > 0) {
          emailNotInGroup = !this.selectedGroup.users.includes(
            this.workflowData.user.addUserEmail
          );
        } else {
          emailNotInGroup = true;
        }
        return courseValid && emailNotInGroup;
      }
      return false;
    },

    computedWorkflowData(): any {
      return {
        group: {
          groups: [...this.workflowData.group.newGroupIds].map((id) => {
            return this.groups.find((group) => group.id === id);
          }),
        },
      };
    },
    selectedGroup(): any {
      if (this.workflowData.user.selectedGroupIndex !== null) {
        const id =
          this.computedWorkflowData.group.groups[
            this.workflowData.user.selectedGroupIndex
          ]?.id;

        if (id) {
          return this.groups.find((group) => group.id === id);
        }
      }
      return undefined;
    },
    selectedGroupUsers(): any {
      if (this.selectedGroup) {
        let users = _.cloneDeep(this.selectedGroup.users);
        users = users.map((email) => {
          return { email: email, company: this.getUserCompany(email) };
        });
        return users;
      } else {
        return undefined;
      }
    },
  },
  methods: {
    moment: function () {
      return moment();
    },
    getCourseId(course: Course) {
      return course.id.split("_")[0];
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    cancelWorkflow() {
      if (confirm("You will loose all your progress. Are you sure?")) {
        this.clearAllData();
        this.e1 = 1;
      }
    },
    clearAllData() {
      this.formValid = {
        step3Valid: false,
        step5Valid: false,
      };
      this.addGroupData = {
        addGroupName: "",
        addGroupCourse: "",
        addGroupCoachImage: "",
        addGroupCoachName: "",
        numberCoachings: 5,
        weeklyFrequency: 2,
        firstCoachingStart: "",
        firstCoachingDuration: 1.5,
        coachObject: {} as Coach,
      };
      this.addGroupDialog = {
        active: false,
        usePreset: true,
        useCoachCollection: true,
        coachingFormValid: false,
      };
      this.workflowData = {
        course: null,
        batch: { name: null, id: null },
        group: { newGroupIds: [] },
        user: {
          selectedGroupIndex: 0,
          addUserDialog: false,
          addUserCompany: {},
          addUserEmail: null,
        },
      };
    },
    finishStep3() {
      if (this.formValid.step3Valid) {
        this.addBatch()?.then((res) => {
          this.workflowData.batch.id = res.id;
          this.e1 += 1;
        });
      }
    },
    finishStep4() {
      if (this.step4Valid && this.workflowData.batch.id) {
        this.$store
          .dispatch("updateDocument", {
            document: batchCollection.doc(this.workflowData.batch.id),
            data: { groups: this.workflowData.group.newGroupIds },
          })
          .then(() => (this.e1 += 1));
      }
    },
    finishStep5() {
      this.$router.push("/batch/" + this.workflowData.batch.id);
      this.clearAllData();
    },
    addBatch() {
      if (this.workflowData.batch.name !== "") {
        return this.$store.dispatch("addDocument", {
          data: { name: this.workflowData.batch.name, groups: [] },
          collection: batchCollection,
        });
      }
    },
    addGroup() {
      if (this.workflowData.course) {
        let _defaultCoaching = { ...this.defaultCoaching };
        _defaultCoaching.courseId = this.workflowData.course;
        let coachings = this._presetCoachings(_defaultCoaching);

        let group: CoachingGroup = {
          users: [],
          course: this.workflowData.course,
          coachings: coachings,
          name: this.addGroupData.addGroupName,
        };

        if (
          this.addGroupDialog.useCoachCollection &&
          this.addGroupData.coachObject.email
        ) {
          group.coaches = [this.addGroupData.coachObject.email];
        }

        this.$store
          .dispatch("addDocument", {
            data: group,
            collection: groupCollection,
          })
          .then((res) => {
            //Set id as property, as it is requiered by our apps
            this.$store.dispatch("updateDocument", {
              document: res,
              data: { id: res.id },
            });
            this.resetAddGroupData();
            this.addGroupDialog.active = false;

            //THIS IS NEW COMPARED TO CoachingGroup.vue:
            this.workflowData.group.newGroupIds.push(res.id);
          });
      } else {
        console.log("Error, no workflowdata.course");
      }
    },
    addUserToSelectedGroup() {
      const newUsers = _.cloneDeep(this.selectedGroup.users);
      newUsers.push(this.workflowData.user.addUserEmail);

      this.$store
        .dispatch("updateDocument", {
          document: groupCollection.doc(this.selectedGroup.id),
          data: { users: newUsers },
        })
        .then(() => {
          this.workflowData.user.addUserDialog = false;
          this.workflowData.user.addUserEmail = null;
        });
    },
    resetAddGroupData(): void {
      this.addGroupData = {
        addGroupName: "",
        addGroupCourse: "",
        addGroupCoachImage: "",
        addGroupCoachName: "",
        numberCoachings: 5,
        weeklyFrequency: 2,
        firstCoachingStart: "",
        firstCoachingDuration: 1.5,
        coachObject: {} as Coach,
      };
    },
    getCoachImage(group: CoachingGroup): string {
      let image = "";
      if (group.coachings.length > 0) {
        image = group.coachings[0].coachImage;
      }
      return image;
    },
    getCoachName(group: CoachingGroup): string {
      let name = "";
      if (group.coachings.length > 0) {
        name = group.coachings[0].coach;
      }
      return name;
    },
    getGroupName(group: CoachingGroup): string {
      return getGroupName(group);
    },
    getUserCompany(email: string) {
      let company = this.companies.find((company) => {
        return company.allUsers.includes(email);
      });
      return company;
    },
    /** Fill coachings if preset */
    _presetCoachings(_defaultCoaching) {
      let coachings;
      if (this.addGroupDialog.usePreset) {
        if (
          this.addGroupDialog.useCoachCollection &&
          this.addGroupData.coachObject
        ) {
          this.addGroupData.addGroupCoachName =
            this.addGroupData.coachObject.name;
          this.addGroupData.addGroupCoachImage =
            this.addGroupData.coachObject.image;
        }
        _defaultCoaching.coach = this.addGroupData.addGroupCoachName;
        _defaultCoaching.coachImage = this.addGroupData.addGroupCoachImage;
        coachings = Array(this.addGroupData.numberCoachings).fill({
          ..._defaultCoaching,
        });
        coachings = coachings.map((element) => {
          return { ...element, id: UniqueIdGenerator.generate() };
        });
        // Check if preset exists and matches number of Sessions
        // If yes: fill enableUntilChapter
        if (
          this.enableUntilChapters[this.addGroupData.addGroupCourse]?.length ===
          this.addGroupData.numberCoachings
        ) {
          coachings = coachings.map((element, index) => {
            let chapter =
              this.enableUntilChapters[this.addGroupData.addGroupCourse][index];
            return {
              ...element,
              enableUntilChapter: chapter,
            };
          });
        }
        if (
          this.addGroupData.weeklyFrequency &&
          this.addGroupData.firstCoachingStart
        ) {
          let s = moment(new Date(this.addGroupData.firstCoachingStart));
          let e = moment(new Date(this.addGroupData.firstCoachingStart)).add(
            this.addGroupData.firstCoachingDuration * 60,
            "minutes"
          );

          coachings = coachings.map((element, index) => {
            let _s = s
              .clone()
              .add(index * 7 * this.addGroupData.weeklyFrequency, "d");
            let _e = e
              .clone()
              .add(index * 7 * this.addGroupData.weeklyFrequency, "d");
            return {
              ...element,
              start: moment.utc(_s).format("YYYY.MM.DD HH:mm:SS"),
              end: moment.utc(_e).format("YYYY.MM.DD HH:mm:SS"),
            };
          });
        }
      } else {
        coachings = [];
      }
      return coachings;
    },
  },
});
